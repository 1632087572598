import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from 'ngb-modal';

import { CoreModule } from '@app/core';
import { StatisticRoutingModule } from './statistic-routing.module';
import { StatisticComponent } from './statistic.component';
import { StatisticService } from './statistic.service';
import { CsvService } from '../services/csv.services';
import { NgbdSortableHeader } from './sortable.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    ModalModule,
    StatisticRoutingModule
  ],
  declarations: [StatisticComponent, NgbdSortableHeader],
  providers: [StatisticService, CsvService]
})
export class StatisticModule {}
