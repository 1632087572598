import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { SuggestionsRoutingModule } from './suggestions/suggestions-routing.module';

import { SharedService } from './shared.service';

@NgModule({
  imports: [CommonModule, SuggestionsRoutingModule],
  declarations: [LoaderComponent, SuggestionsComponent],
  exports: [LoaderComponent, SuggestionsComponent],
  providers: [SharedService]
})
export class SharedModule {}
