import { Component, ElementRef, QueryList, ViewChildren, ViewChild, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ModalManager } from 'ngb-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';
import { DrugsService } from './drugs.service';
import { HomeService } from '../home/home.service';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';

@Component({
  selector: 'app-drugs',
  templateUrl: './drugs.component.html',
  styleUrls: ['./drugs.component.scss'],
  providers: [NgbModalConfig, NgbModal, DecimalPipe]
})
export class DrugsComponent implements OnInit {
  isLogged = false;
  closeResult: string;
  confirmClearModal: any;
  credentials: any = {};
  quote: string | undefined;
  error: string | undefined;
  filterFormError: string = '';
  filterForm!: FormGroup;
  seriaCompleteForm!: FormGroup;
  isLoading = false;
  isTermSearchLoading = false;
  isSamplesLoading = false;
  isUserSamplesLoading = false;
  isWarningsLoading = false;
  showfull = false;
  show = false;
  showComplete = false;
  autocomplete = false;
  progress = false;
  term_search_str: string = '';
  term_data: any = [];
  seria_search: string = '';
  seria_data: any = {};
  samples_data: any = [];
  samples_data_full: any = [];
  user_samples_data: any = [];
  user_samples_data_full: any = [];
  warnings_data: any = [];
  warnings_data_full: any = [];
  drugs_data: any = [];
  mf_column_data: any = [
    { id: 'Sample', name: 'Sample' },
    { id: 'title', name: 'Sample title' },
    { id: 'channel_sources', name: 'Source name' },
    { id: 'characteristics_ch1', name: 'Characteristic' },
    { id: 'Series', name: 'Series' }
  ];

  selectedColumn: any = '';

  mf_column_type_data: any = [
    { id: 'drug_name', name: 'Drug' },
    { id: 'drug_dose', name: 'Dose' },
    { id: 'drug_timepoint', name: 'Timepoint' },
    { id: 'drug_therapy_response', name: 'Therapy response' }
  ];
  selectedColumnType: any = '';

  mf_complete_status_data = [
    { id: 'done', name: 'Done' },
    { id: 'failed', name: 'Failed' },
    { id: 'primary', name: 'Primary' }
  ];
  selectedCompleteStatus: any = '';

  dose_types: any = [
    { id: 'None', name: 'None' },
    { id: 'mM', name: 'mM' },
    { id: 'M', name: 'M' },
    { id: 'μCi/ml', name: 'μCi/ml' },
    { id: 'μM', name: 'μM' },
    { id: 'nM', name: 'nM' },
    { id: 'ME', name: 'ME' },
    { id: 'ng/ml', name: 'ng/ml' },
    { id: 'mg/ml', name: 'mg/ml' },
    { id: '%', name: '%' }
  ];

  timepoint_types: any = [
    { id: 'None', name: 'None' },
    { id: 'seconds', name: 'seconds' },
    { id: 'minutes', name: 'minutes' },
    { id: 'hours', name: 'hours' },
    { id: 'days', name: 'days' },
    { id: 'weeks', name: 'weeks' },
    { id: 'months', name: 'months' },
    { id: 'years', name: 'years' }
  ];

  therapy_response_types: any = [
    { id: 'None', name: 'None' },
    { id: 'responder', name: 'Responder' },
    { id: 'partially_responder', name: 'Partially responder' },
    { id: 'non_responder', name: 'Non-responder' }
  ];

  has_results = false;

  loaders: any = { history: 0 };
  pagination: any = { samples: 1, user_samples: 1, warnings: 1 };

  history: any = [];

  mf_column_filter_values: any = [];
  selectedColumnFilter: any = '';

  page = 1;
  currentPage = 1;

  itemsPerPage = 5;
  pageSize: number = 9;
  samplesPageSize: number = 50;
  selectedSamplePageSize: number = 50;
  listSizes = [{ id: 50, name: '50' }, { id: 100, name: '100' }, { id: 500, name: '500' }];

  filter: FormControl;
  filter_warnings: FormControl;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private homeService: HomeService,
    private drugsService: DrugsService
  ) {
    this.createFilterForm();
    this.createSeriaCompleteForm();
  }

  openModal(targetModal: any) {
    this.confirmClearModal = this.modalService
      .open(targetModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static'
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    if (this.credentialsService.isAuthenticated()) {
      this.isLogged = true;
      this.credentials = this.credentialsService.get_credentials();
      console.log(this.credentials);
    } else {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], { replaceUrl: true });
    }

    this.filter = new FormControl('');
    this.filter_warnings = new FormControl('');

    this.homeService
      .getDrugs()
      .pipe(finalize(() => {}))
      .subscribe((drugs_data_json: any) => {
        let drugs_data = JSON.parse(drugs_data_json);
        if (drugs_data.length) {
          this.drugs_data = drugs_data;
        }
        this.drugs_data.push({ id: 'None', name: 'None' });
      });
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      mf_column: [''],
      mf_regex: [''],
      mf_column_filter: [''],
      mf_filter: ['']
    });
  }

  private createSeriaCompleteForm() {
    this.seriaCompleteForm = this.formBuilder.group({
      mf_complete_status: ['']
    });
  }

  isString(x: any) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  search(text: string, data: any, column: string = '', exact: boolean = false) {
    let copyData = JSON.parse(JSON.stringify(data));

    const term = text.toLowerCase();
    if (column.length > 0) {
      console.log('Search in column: ' + column);
      return copyData.filter((comp: any) => {
        const term = text.toLowerCase();
        if (exact) {
          return comp[column].toLowerCase() === term;
        } else {
          return comp[column].toLowerCase().includes(term);
        }
      });
    } else {
      console.log('Search in all columns');
      return copyData.filter((obj: any) => {
        return Object.keys(obj).reduce((acc, curr) => {
          //console.log(curr);
          return acc || (this.isString(obj[curr]) && obj[curr].toLowerCase().includes(term));
        }, false);
      });
    }
  }

  replaceData(params: any, data: any) {
    console.log('Start replaceData');
    let copyData = JSON.parse(JSON.stringify(data));
    let replaceData = {};

    if (params['mf_column_filter'] == 'drug_name') {
      let drug = this.search(params['mf_filter'], this.drugs_data, 'id', true);
      if (!drug.length) {
        console.log('Drug not found in allowed');
      }

      console.log(drug);
      replaceData['drug_ontology_id'] = drug[0]['id'];
      replaceData['drug_name'] = drug[0]['name'];
    }

    if (params['mf_column_filter'] == 'drug_dose') {
      replaceData['drug_dose'] = params['mf_filter'];
    }

    if (params['mf_column_filter'] == 'drug_timepoint') {
      replaceData['drug_timepoint'] = params['mf_filter'];
    }

    if (params['mf_column_filter'] == 'drug_therapy_response') {
      replaceData['drug_therapy_response'] = params['mf_filter'];
    }

    console.log(replaceData);

    let newData: any = [];
    if (replaceData) {
      copyData.forEach((element: any, index: any) => {
        newData[index] = Object.assign(element, replaceData);
      });
    }

    console.log(newData);
    return newData;
  }

  onSort({ data, column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      return;
    }

    console.log('data:' + data + ',column:' + column + ',direction:' + direction);

    let cond = direction === 'asc' ? column : '-' + column;
    let sel_data = this[data];
    if (sel_data.data) {
      sel_data = sel_data.data;
    }

    sel_data.sort(this.dynamicSort(cond));

    return;
  }

  dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  onColumnFilterSelected(value: string) {
    let params = this.filterForm.value;
    console.log('the input value is ' + value + ', form value is ' + params['mf_column_filter']);
    if (params['mf_column_filter'] == 'drug_name') {
      this.mf_column_filter_values = this.drugs_data;
    }

    if (params['mf_column_filter'] == 'drug_dose') {
      this.mf_column_filter_values = this.dose_types;
    }

    if (params['mf_column_filter'] == 'drug_timepoint') {
      this.mf_column_filter_values = this.timepoint_types;
    }

    if (params['mf_column_filter'] == 'drug_therapy_response') {
      this.mf_column_filter_values = this.therapy_response_types;
    }

    this.has_results = false;
    this.term_data = [];
    this.term_search_str = '';
  }

  getSuggestions() {
    if (this.seria_search.length > 1 && !this.isLoading) {
      this.isLoading = true;

      this.seria_data = {};
      this.warnings_data = {};
      this.warnings_data_full = {};
      this.samples_data = {};
      this.samples_data_full = {};
      this.selectedCompleteStatus = '';

      console.log(this.isLoading);
      let seria = this.seria_search;
      console.log(seria);

      this.homeService
        .getSeria(seria)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((seria_data: any) => {
          seria_data = JSON.parse(seria_data);
          if (seria_data.length) {
            this.seria_data = seria_data[0];
            this.getRelatedData(seria);

            if (this.seria_data['mf_complete_status']) {
              this.selectedCompleteStatus = this.seria_data['mf_complete_status'];
            }
          }
        });

      console.log(this.isLoading);
    }
  }

  getTerms() {
    //getTermBySync
    if (this.term_search_str.length > 3 && !this.isTermSearchLoading) {
      this.isTermSearchLoading = true;
      let term = this.term_search_str;
      let params = this.filterForm.value;
      console.log(params);
      let term_type = 'na';
      if (params['mf_column_filter'] == 'drug_name') {
        term_type = 'drugs';
      }

      this.homeService
        .getTermBySync(term, term_type)
        .pipe(
          finalize(() => {
            this.isTermSearchLoading = false;
          })
        )
        .subscribe((json_term_data: any) => {
          let term_data = JSON.parse(json_term_data);
          if (term_data) {
            this.term_data = term_data;
            this.has_results = true;
          }
        });
    }
  }

  onSelectColumn(select_data: any) {
    console.log('onSelectColumn');
    console.log(select_data);
    let params = this.filterForm.value;
    console.log(params['mf_filter']);
    this.selectedColumnFilter = select_data;
    this.modalService.dismissAll();
    this.has_results = false;
    this.term_data = [];
    this.term_search_str = '';
  }

  getRelatedData(seria: string) {
    let data = this.seria_data;
    this.isSamplesLoading = true;
    if (data['sample_count'] > 0) {
      //Samples
      this.getSamples(seria);

      //Warnings
      this.homeService
        .getWarnings(seria)
        .pipe(
          finalize(() => {
            this.isWarningsLoading = false;
          })
        )
        .subscribe((warnings_data: any) => {
          this.warnings_data = JSON.parse(warnings_data);
          this.warnings_data_full = JSON.parse(warnings_data);
        });

      //UserSamples
      this.getUserSamples(seria);
    }

    if (this.warnings_data) {
      this.filter_warnings.valueChanges.subscribe((search_text: string) => {
        if (search_text.length > 2) {
          this.warnings_data = this.search(search_text, this.warnings_data_full);
        } else {
          this.warnings_data = JSON.parse(JSON.stringify(this.warnings_data_full));
        }
      });
    }

    if (this.samples_data) {
      //Subscribe on filter
      this.filter.valueChanges.subscribe((search_text: string) => {
        if (search_text.length > 2) {
          this.samples_data = this.search(search_text, this.samples_data_full);
        } else {
          this.samples_data = JSON.parse(JSON.stringify(this.samples_data_full));
        }
      });
    }
  }

  getSamples(seria: string) {
    this.homeService
      .getSamples(seria)
      .pipe(
        finalize(() => {
          this.isSamplesLoading = false;
        })
      )
      .subscribe((samples_data: any) => {
        this.samples_data = JSON.parse(samples_data);
        this.samples_data_full = JSON.parse(samples_data);
        console.log('Loaded ' + this['samples_data'].length);
      });
  }

  getUserSamples(seria: string) {
    //UserSamples
    this.homeService
      .getUserSamples(seria)
      .pipe(
        finalize(() => {
          this.isUserSamplesLoading = false;
        })
      )
      .subscribe((samples_data: any) => {
        this.user_samples_data = JSON.parse(samples_data);
        this.user_samples_data_full = JSON.parse(samples_data);
      });
  }

  showCompleteForm() {
    if (!this.showComplete) {
      this.showComplete = true;
      this.show = false;
    } else {
      this.showComplete = false;
    }
  }

  showModifyForm() {
    if (!this.show) {
      this.show = true;
      this.showComplete = false;
    } else {
      this.show = false;
      this.samples_data = JSON.parse(JSON.stringify(this.samples_data_full));
    }
  }

  modify_test() {
    let params = this.filterForm.value;
    //console.log(params);

    let samples_data = this.search(params['mf_regex'], this.samples_data_full, params['mf_column']);
    if (samples_data.length > 0) {
      console.log('Found ' + samples_data.length + ' rows');
      if (params.mf_filter) {
        this.samples_data = this.replaceData(params, samples_data);
      } else {
        this.samples_data = samples_data;
      }
    } else {
      this.samples_data = [];
    }

    return;
  }

  synonym_search(content: any) {
    let params = this.filterForm.value;

    if (params && params['mf_column_filter']) {
      if (params['mf_column_filter'] == 'drug_name') {
        console.log('Open form to search by synonyms');
        this.openModal(content);
      }
    }
  }

  modify_clear(content: any) {
    console.log('Clear all modifications');
    this.openModal(content);
  }

  clearUserSamples(seria: string) {
    console.log('Do clear user samples from seria ' + seria);

    this.isLoading = true;
    let action_data = { action: 'delete', seria: seria };

    const data$ = this.homeService.delUserSamples(action_data);
    data$
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        response => {
          console.log(response);
          let resp = JSON.parse(JSON.stringify(response));
          console.log(resp);

          if (resp['error'] && resp['error'].length > 0) {
            return false;
          } else {
            console.log('Reload seria data');
            this.getUserSamples(this.seria_search);
            this.getSamples(this.seria_search);
          }
        },
        error => {
          console.log(error);
          return false;
        }
      );

    this.modalService.dismissAll();
  }

  complete_status_save() {
    let params = this.seriaCompleteForm.value;
    console.log(params);
    console.log(this.seria_data);
    params['seria'] = this.seria_data['Series'];

    const data$ = this.homeService.setUserSeriaStatus(params);
    data$.pipe(finalize(() => {})).subscribe(
      response => {
        let resp = JSON.parse(JSON.stringify(response));
        console.log(resp);

        if (resp['error'] && resp['error'].length > 0) {
          return false;
        }
      },
      error => {
        console.log(error);
        return false;
      }
    );
  }

  modify_save() {
    let params = this.filterForm.value;

    let samples_data = this.search(params['mf_regex'], this.samples_data_full, params['mf_column']);
    if (samples_data.length > 0) {
      console.log('Found ' + samples_data.length + ' rows');
      this.samples_data = this.replaceData(params, samples_data);
      if (this.samples_data.length > 0) {
        this.isLoading = true;
        const data$ = this.homeService.setUserSamples(this.samples_data);
        data$
          .pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
          .subscribe(
            response => {
              console.log(response);
              let resp = JSON.parse(JSON.stringify(response));
              console.log(resp);

              if (resp['error'] && resp['error'].length > 0) {
                this.filterFormError = resp['error'];
                return false;
              } else {
                this.getUserSamples(this.seria_search);
                this.getSamples(this.seria_search);
              }
            },
            error => {
              console.log(error);
              this.filterFormError = error;
              return false;
            }
          );

        this.filterForm.reset();
        this.filter = new FormControl('');
      }
    }

    return;
  }

  calculateNotAnnot() {
    return this.samples_data_full.length - this.user_samples_data.length;
  }

  onSwitchTabs(e: any) {
    if (e && e.nextId) {
      console.log(e.nextId);
    }
  }

  selectMaxSize($event: any, page_size: number) {
    let selectedSamplePageSize = Number(
      page_size
        .toString()
        .split(':')[1]
        .replace(/\s/g, '')
    );
    this.samplesPageSize = selectedSamplePageSize;
  }

  scrollTo(section: any) {
    document.querySelector('#' + section).scrollIntoView();
  }

  // Validates URL
  urlValidator(url: string) {
    if (!url.length) {
      return null;
    }
    const URL_REGEXP = /^(http?|https|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|ru|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    //url.markAsTouched();
    if (!URL_REGEXP.test(url)) {
      return null;
    }

    return true;
  }
}
