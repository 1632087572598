import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HomeService {
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
  }

  getTerm(search_text: string, search_type: string): Observable<any> {
    let url = '/v3/platform/search?q=' + search_text;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTermBySync(search_text: string, search_type: string): Observable<any> {
    let url = '/v3/platform/synsearch?q=' + search_text + '&type=' + search_type;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getDisease(disease_id: string): Observable<any> {
    let url = '/v3/platform/diseases?disease_id=' + disease_id;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getSeria(seria_id: any): Observable<any> {
    const salt = new Date().getTime();
    let url = '/v3/platform/serias?salt=' + salt + '&seria=' + seria_id;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

  getSamples(seria_id: any): Observable<any> {
    let url = '/v3/platform/samples?seria=' + seria_id;
    console.log('get url: ' + url);
    return this.httpClient.get(url, this.httpOptions);
  }

  getUserSamples(seria_id: any): Observable<any> {
    const salt = new Date().getTime();
    let url = '/v3/platform/user_samples?salt=' + salt + '&seria=' + seria_id;
    console.log('get url: ' + url);
    return this.httpClient.get(url, this.httpOptions);
  }

  setUserSamples(formData: any): Observable<any> {
    let url = '/v3/platform/user_samples';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  setUserSeriaStatus(formData: any): Observable<any> {
    let url = '/v3/platform/user_serias_mng';
    console.log('set url: ' + url);
    return this.httpClient.post(url, formData);
  }

  delUserSamples(formData: any): Observable<any> {
    let url = '/v3/platform/user_samples_mng';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  getWarnings(seria_id: any): Observable<any> {
    let url = '/v3/platform/warnings?seria=' + seria_id;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

  getDiseases(ontology_id: any = ''): Observable<any> {
    let url = '/v3/platform/diseases';
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getDrugs(ontology_id: any = ''): Observable<any> {
    let url = '/v3/platform/drugs';
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTissues(ontology_id: any = ''): Observable<any> {
    let url = '/v3/platform/tissues';
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }
}
