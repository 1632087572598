import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngb-modal';

import { CoreModule } from '@app/core';
import { DownloadRoutingModule } from './download-routing.module';
import { DownloadComponent } from './download.component';
import { DownloadService } from './download.service';
import { NgbdSortableHeader } from './sortable.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    ModalModule,
    DownloadRoutingModule
  ],
  declarations: [DownloadComponent, NgbdSortableHeader],
  providers: [DownloadService]
})
export class DownloadModule {}
