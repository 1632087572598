import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngb-modal';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { DrugsRoutingModule } from './drugs-routing.module';
import { DrugsComponent } from './drugs.component';
import { HomeService } from '../home/home.service';
import { DrugsService } from './drugs.service';

import { NgbdSortableHeader } from './sortable.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    ModalModule,
    SharedModule,
    DrugsRoutingModule
  ],
  declarations: [DrugsComponent, NgbdSortableHeader],
  providers: [DrugsService, HomeService]
})
export class DrugsModule {}
