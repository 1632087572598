import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { TestRoutingModule } from './test-routing.module';
import { TestComponent } from './test.component';

@NgModule({
  imports: [CommonModule, TranslateModule, TestRoutingModule],
  declarations: [TestComponent]
})
export class TestModule {}
