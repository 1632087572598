import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //console.log('prev:' + this.router.url);
    //console.log(route.url);
    //console.log(state.url);

    let allow_pages = ['/index', '/about'];
    //console.log('current:' + route._routerState.url);

    if (this.credentialsService.isAuthenticated()) {
      return true;
    }

    if (allow_pages.indexOf(state.url) !== -1) {
      console.log('user need to auth');
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}
