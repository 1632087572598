import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, CredentialsService, untilDestroyed } from '@app/core';

import { LoginService } from './login.service';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  isLogged = false;
  credentials: any = { email: '' };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private loginService: LoginService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {
    if (this.credentialsService.isAuthenticated()) {
      this.isLogged = true;
      this.credentials = this.credentialsService.get_credentials();
    }
  }

  ngOnDestroy() {}

  login() {
    this.isLoading = true;
    let params = this.loginForm.value;

    //const login$ = this.authenticationService.login(this.loginForm.value);
    const login$ = this.loginService.login(params);
    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        credentials => {
          console.log(credentials);
          let response = JSON.parse(credentials);
          this.credentialsService.setCredentials({ email: params.username, token: response.token }, params.remember);
          log.debug(`${params.username} successfully logged in`);
          this.error = '';
          this.isLogged = true;

          this.router.navigate([this.route.snapshot.queryParams.redirect || '/home'], { replaceUrl: true });
        },
        error => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  logout() {
    this.isLoading = true;
    const logout$ = this.loginService.logout();
    logout$
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        credentials => {
          this.credentialsService.setCredentials();
          this.error = '';
          this.isLogged = false;

          this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        },
        error => {
          log.debug(`Logout error: ${error}`);
          this.error = error;
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
