import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LandingComponent } from '../../landing/landing.component';
import { HomeComponent } from '../../home/home.component';

import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/index', pathMatch: 'full' },
    { path: 'index', component: LandingComponent, data: { title: extract('Omics Data') } },
    { path: 'home', component: HomeComponent, data: { title: extract('Home') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SuggestionsRoutingModule {}
