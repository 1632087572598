import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    Pragma: 'no-cache',
    Expires: '0'
  })
};

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

@Injectable()
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  login(context: LoginContext): Observable<any> {
    const data = {
      email: context.username,
      password: context.password
    };

    let url = '/v3/platform/login';
    console.log('get url: ' + url);
    return this.httpClient.post(url, data);
  }

  status(token: string): Observable<any> {
    let url = '/v3/platform/userstatus/' + token + '?cur_time = ' + new Date().getTime();
    return this.httpClient.get(url);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<any> {
    let url = '/v3/platform/logout';
    console.log('get url: ' + url);
    return this.httpClient.post(url, {});

    // Customize credentials invalidation here
    //this.credentialsService.setCredentials();
    return of(true);
  }
}
