import { Component, ElementRef, QueryList, ViewChildren, ViewChild, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { ModalManager } from 'ngb-modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, CredentialsService } from '@app/core';
import { DownloadService } from './download.service';

import { NgbdSortableHeader, SortEvent } from './sortable.directive';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
  providers: [NgbModalConfig, NgbModal, DecimalPipe]
})
export class DownloadComponent implements OnInit {
  isLogged = false;
  closeResult: string;
  confirmClearModal: any;
  credentials: any = {};
  quote: string | undefined;
  error: string | undefined;
  filterFormError: string = '';
  filterForm!: FormGroup;
  dwlForm!: FormGroup;
  save_dwl_note_result: string = '';
  save_dwl_note_status: boolean = true;
  isLoading = false;

  group_data: any = {};
  group_seria_data: any = [];

  has_results = false;

  loaders: any = { isSeriaGroupsLoading: 0, isGroupSeriesLoading: 0, isSeriaGroupsInRequestLoading: 0 };
  pagination: any = { groups: 1, group_series: 1, group_series_request: 1, group_series_error: 1 };
  geo_stat: any = { total: 0, process: 0 };
  mf_column_filter_values: any = [];
  selectedColumnFilter: any = '';

  page = 1;
  currentPage = 1;
  itemsPerPage = 5;
  pageSize: number = 100;

  listSizes = [{ id: 100, name: '100' }, { id: 500, name: '500' }];

  seria_groups_data: any = [];
  seria_groups_data_full: any = [];
  group_series_data: any = [];
  group_series_data_full: any = [];

  group_series_data_request: any = [];
  group_series_data_request_full: any = [];

  geo_level: string = 'group';
  current_group: string = '';

  filter_geo_series: FormControl;
  filter_geo_groups: FormControl;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  //@ViewChild('confirmDelete') confirmDelete: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private downloadService: DownloadService
  ) {
    this.createFilterForm();
    this.createNoteDwlForm();
  }

  openModal(targetModal: any) {
    this.confirmClearModal = this.modalService
      .open(targetModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        backdrop: 'static'
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    if (this.credentialsService.isAuthenticated()) {
      this.isLogged = true;
      this.credentials = this.credentialsService.get_credentials();
      console.log(this.credentials);
    } else {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/login'], { replaceUrl: true });
    }

    this.filter_geo_series = new FormControl('');
    this.filter_geo_groups = new FormControl('');
    this.getSeriaGroups();
  }

  isString(x: any) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  search(text: string, data: any, column: string = '', exact: boolean = false) {
    let copyData = JSON.parse(JSON.stringify(data));

    const term = text.toLowerCase();
    if (column.length > 0) {
      console.log('Search in column: ' + column);
      return copyData.filter((comp: any) => {
        const term = text.toLowerCase();
        if (exact) {
          return comp[column].toLowerCase() === term;
        } else {
          return comp[column].toLowerCase().includes(term);
        }
      });
    } else {
      console.log('Search in all columns');
      return copyData.filter((obj: any) => {
        return Object.keys(obj).reduce((acc, curr) => {
          //console.log(curr);
          return acc || (this.isString(obj[curr]) && obj[curr].toLowerCase().includes(term));
        }, false);
      });
    }
  }

  openGroupSeries(seria_group: string = '') {
    console.log('openGroupSeries: ' + seria_group);
    if (seria_group == '') {
      this.geo_level = 'group';
      console.log('geo_level is: ' + this.geo_level);
      return;
    }

    this.geo_level = 'seria';
    this.current_group = seria_group;
    this.getGroupSeries(seria_group);
    console.log('geo_level is: ' + this.geo_level);
    return;
  }

  getSeriaGroups() {
    this.downloadService
      .getSeriaGroups()
      .pipe(
        finalize(() => {
          this.loaders.isSeriaGroupsLoading = false;
        })
      )
      .subscribe((seria_groups_data: any) => {
        this.seria_groups_data = JSON.parse(seria_groups_data);
        this.seria_groups_data_full = JSON.parse(seria_groups_data);
        console.log('Loaded ' + this['seria_groups_data'].length);

        if (this.seria_groups_data) {
          this.filter_geo_groups.valueChanges.subscribe((search_text: string) => {
            if (search_text.length > 2) {
              this.seria_groups_data = this.search(search_text, this.seria_groups_data_full);
            } else {
              this.seria_groups_data = JSON.parse(JSON.stringify(this.seria_groups_data_full));
            }
          });
        }
      });
  }

  getSeriaGroupsStat(stat: string) {
    this.downloadService
      .getSeriaGroupsInStat(stat)
      .pipe(finalize(() => {}))
      .subscribe((group_series_data_request: any) => {
        this.group_series_data_request = JSON.parse(group_series_data_request);
        this.group_series_data_request_full = JSON.parse(group_series_data_request);
        console.log('Loaded ' + this['group_series_data_request'].length);
      });
  }

  calc_stats(data: any) {
    let stat = { total: data.length, process: 0, empty: 0, ready: 0 };

    data.forEach((element: any, index: any) => {
      if (element.status == 'in-process') {
        stat.process++;
      }

      if (element.status == 'empty') {
        stat.empty++;
      }

      if (element.status == 'ready') {
        stat.ready++;
      }
    });

    return stat;
  }

  getGroupSeries(seria_group: string) {
    //UserSamples
    this.downloadService
      .getGroupSeries(seria_group)
      .pipe(
        finalize(() => {
          this.loaders.isGroupSeriesLoading = false;
        })
      )
      .subscribe((group_series_data: any) => {
        this.group_series_data = JSON.parse(group_series_data);
        this.group_series_data_full = JSON.parse(group_series_data);

        this.geo_stat = this.calc_stats(this.group_series_data_full);

        if (this.group_series_data) {
          this.filter_geo_series.valueChanges.subscribe((search_text: string) => {
            if (search_text.length > 2) {
              this.group_series_data = this.search(search_text, this.group_series_data_full);
            } else {
              this.group_series_data = JSON.parse(JSON.stringify(this.group_series_data_full));
            }
          });
        }
      });
  }

  add_dwl_note(content: any) {
    this.openModal(content);
  }

  download_seria(seria_group: string, seria_name: string) {
    this.downloadService
      .setSeriaDownload({ action: 'download_seria', seria_group: seria_group, seria_name: seria_name })
      .pipe(finalize(() => {}))
      .subscribe((json_data: any) => {
        let result = JSON.parse(json_data);
        if (result) {
          this.group_series_data_full.forEach((element: any, index: any) => {
            if (element.seria_name == seria_name) {
              this.group_series_data[index]['status'] = 'in-process';
              this.geo_stat['process']++;
            }
          });
        }
      });
  }

  createNoteDwlForm() {
    console.log('current group is ' + this.current_group);
    this.dwlForm = this.formBuilder.group({
      mf_group: this.current_group,
      mf_seria: ''
    });
  }

  save_dwl_note(content: any) {
    this.save_dwl_note_result = '';
    this.save_dwl_note_status = true;

    let formData = this.dwlForm.value;
    console.log(this.current_group);

    if (formData['mf_group'] === '') {
      formData['mf_group'] = this.current_group;
    }

    formData['action'] = 'add_group_seria';
    this.downloadService
      .setAddGroupSeria(formData)
      .pipe(finalize(() => {}))
      .subscribe((json_data: any) => {
        let note_result = JSON.parse(json_data);
        if (note_result) {
          console.log('Note added successfuly');
          console.log(note_result);
          this.save_dwl_note_result = 'Added successfuly';
        } else {
          this.save_dwl_note_result = 'Added error';
          this.save_dwl_note_status = false;
        }
      });

    this.modalService.dismissAll();
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      mf_status: ['']
    });
  }

  selectMaxSize($event: any) {
    console.log($event);
    //this.samplesPageSize = this.listSizes[$event];
    //console.log("Selected: " + this.samplesPageSize + " rows");
    this.pageSize = $event;
  }

  onTabChange(e: any) {
    this.group_series_data_request = [];
    this.group_series_data_request_full = [];

    if (e && e.nextId) {
      console.log(e);
    }

    if (e.nextId == 'in_request') {
      console.log(e);
      console.log('Get in request');
      this.getSeriaGroupsStat('in-process');
    }

    if (e.nextId == 'in_error') {
      console.log(e);
      console.log('Get in error');
      this.getSeriaGroupsStat('error');
    }
  }

  onSort({ data, column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '') {
      return;
    }

    console.log('data:' + data + ',column:' + column + ',direction:' + direction);

    let cond = direction === 'asc' ? column : '-' + column;
    let sel_data = this[data];
    if (sel_data.data) {
      sel_data = sel_data.data;
    }

    sel_data.sort(this.dynamicSort(cond));

    return;
  }

  dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
