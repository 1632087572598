import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class DownloadService {
  private httpOptions: any;

  constructor(private httpClient: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
        Pragma: 'no-cache',
        Expires: '0'
      })
    };
  }

  getSeriaGroups(): Observable<any> {
    let url = '/v3/platform/downloader';
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getGroupSeries(seria_group: string = ''): Observable<any> {
    const salt = new Date().getTime();
    let url = '/v3/platform/downloader?salt=' + salt + '&seria_group=' + seria_group;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

  getSeriaGroupsInStat(stat_type: string = ''): Observable<any> {
    const salt = new Date().getTime();
    let url = '/v3/platform/downloader/in_stat?status=' + stat_type + '&salt=' + salt;
    console.log('get url: ' + url);
    return this.httpClient.get(url);
  }

  setSeriaDownload(formData: any): Observable<any> {
    let url = '/v3/platform/downloader/download?action=download_seria';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }

  setAddGroupSeria(formData: any): Observable<any> {
    let url = '/v3/platform/downloader/download?action=add_group_seria';
    console.log('get url: ' + url);
    return this.httpClient.post(url, formData);
  }
}
