import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LandingComponent } from '../landing/landing.component';
import { DrugsComponent } from './drugs.component';

import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'drugs', component: DrugsComponent, data: { title: extract('Drugs annotation') } }])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DrugsRoutingModule {}
