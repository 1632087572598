import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {
  //@Input() searchResults: any = {hits: 0, disease: [], tissue: []};
  @Input() searchResults: any = [];
  @Input() searchType: string = '';
  @Output() selectResult = new EventEmitter();
  //: any = {select_type: '', select_data: {}};

  constructor() {}

  ngOnInit() {
    //console.log('diseases:' + this.searchResults.disease.length);
    //console.log('targets:' + this.searchResults.target.length);
    console.log('app-suggestions');
    console.log(this.searchResults);

    //delete this.searchResults.hits;

    //if(!this.searchResults.disease.length){
    //    delete this.searchResults.disease;
    //}

    //if(!this.searchResults.target.length){
    //    delete this.searchResults.tissue;
    //}
  }

  selectTerm(select_data: any, select_type: string) {
    this.selectResult = select_data.id;
    console.log('selectTerm');
    console.log(this.selectResult);
  }
}
