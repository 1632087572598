import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': '*/*'
  })
};

@Injectable()
export class SharedService {
  constructor(private httpClient: HttpClient) {}

  dynamicSort(property: any) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a: any, b: any) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  getCombChart(
    term: string,
    org_name: string = '',
    use_synonyms: number = 1,
    range_years: string = ''
  ): Observable<any> {
    let url = '/v3/platform/combstat?q=' + term + '&use_synonyms=' + use_synonyms;
    if (org_name.length > 2) {
      url += '&org_name=' + org_name;
    }

    if (range_years.length > 8) {
      url += '&range_years=' + range_years;
    }

    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getCombPharms(
    term: string,
    org_name: string = '',
    use_synonyms: number = 1,
    range_years: string = ''
  ): Observable<any> {
    let url = '/v3/platform/combstat_pharm?q=' + term + '&use_synonyms=' + use_synonyms;
    if (org_name.length > 2) {
      url += '&org_name=' + org_name;
    }

    if (range_years.length > 8) {
      url += '&range_years=' + range_years;
    }

    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getDrugs(term: string): Observable<any> {
    let url = '/v3/platform/drugs?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTrials(
    term: string,
    org_name: string = '',
    use_synonyms: number = 1,
    range_years: string = '',
    chart_name = ''
  ): Observable<any> {
    let url = '/v3/platform/trials?q=' + term + '&use_synonyms=' + use_synonyms;
    if (org_name.length > 2) {
      url += '&org_name=' + org_name;
    }

    if (range_years.length > 8) {
      url += '&range_years=' + range_years;
    }

    if (chart_name.length > 5) {
      url += '&chart=' + chart_name;
    }

    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTrialsOrgs(term: string): Observable<any> {
    let url = '/v3/platform/trials_orgs?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTrialsOrg(term: string, org_name: string, use_synonyms: number = 1): Observable<any> {
    let url = '/v3/platform/trials?q=' + term + '&org_name=' + org_name + '&use_synonyms=' + use_synonyms;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTrialsPersons(term: string): Observable<any> {
    let url = '/v3/platform/trials_persons?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getPubs(term: string): Observable<any> {
    let url = '/v3/platform/pubs?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getPubsAuthors(term: string): Observable<any> {
    let url = '/v3/platform/pubs_authors?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getProjects(
    term: string,
    org_name: string = '',
    use_synonyms: number = 1,
    range_years: string = ''
  ): Observable<any> {
    let url = '/v3/platform/projects?q=' + term + '&use_synonyms=' + use_synonyms;
    if (org_name.length > 2) {
      url += '&org_name=' + org_name;
    }

    if (range_years.length > 8) {
      url += '&range_years=' + range_years;
    }

    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getProjectsOrgs(term: string): Observable<any> {
    let url = '/v3/platform/projects_orgs?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getProjectsOrg(term: string, org_name: string, use_synonyms: number = 1): Observable<any> {
    let url = '/v3/platform/projects?q=' + term + '&org_name=' + org_name + '&use_synonyms=' + use_synonyms;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getProjectsPersons(term: string): Observable<any> {
    let url = '/v3/platform/projects_persons?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getPatents(term: string, org_name: string = '', use_synonyms: number = 1, range_years: string = ''): Observable<any> {
    let url = '/v3/platform/patents?q=' + term + '&use_synonyms=' + use_synonyms;
    if (org_name.length > 2) {
      url += '&org_name=' + org_name;
    }

    if (range_years.length > 8) {
      url += '&range_years=' + range_years;
    }

    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getPatentsOrgs(term: string): Observable<any> {
    let url = '/v3/platform/patents_orgs?q=' + term;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getPatentsOrg(term: string, org_name: string, use_synonyms: number = 1): Observable<any> {
    let url = '/v3/platform/patents?q=' + term + '&org_name=' + org_name + '&use_synonyms=' + use_synonyms;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }

  getTweets(type: string, id: string): Observable<any> {
    let url = '/v3/platform/tweets?type=' + type + '&id=' + id;
    console.log('get url: ' + url);
    return this.httpClient.cache().get(url);
  }
}
